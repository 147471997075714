"use client";

import { useRef } from "react";
import { Provider } from "react-redux";

import { AppStore, makeStore } from "redux/store";

export default function ReduxProvider({
  children,
  store,
}: {
  children: React.ReactNode;
  store?: AppStore;
}) {
  const storeRef = useRef(store);

  if (!storeRef.current) {
    storeRef.current = makeStore();
  }

  return <Provider store={storeRef.current}>{children}</Provider>;
}
